.hero-section {
  position: relative;
  padding: 80px 20px;
  min-height: 80vh;
  background: linear-gradient(135deg, #850212 0%, #bb5a66 100%);
  overflow: hidden;
}

.hero-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;
  position: relative;
  z-index: 2;
}

.hero-content h1 {
  font-size: 3.5em;
  line-height: 1.2;
  margin-bottom: 24px;
  color: #ffffff;
}

.hero-content .highlight {
  color: #ffffff;
  font-weight: 700;
  text-decoration: underline;
  -webkit-text-fill-color: #ffffff;
  text-decoration-color: rgba(255, 255, 255, 0.3);
}

.hero-content p {
  font-size: 1.2em;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 32px;
  line-height: 1.6;
}

.hero-buttons {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
}

.primary-button, .secondary-button {
  padding: 15px 30px;
  border-radius: 30px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.primary-button {
  background: #ffffff;
  color: #850212;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.secondary-button {
  border: 2px solid #ffffff;
  color: #ffffff;
}

.primary-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.secondary-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.hero-stats {
  display: flex;
  gap: 40px;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-number {
  font-size: 2em;
  font-weight: 700;
  color: #ffffff;
}

.stat-label {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.9em;
  margin-top: 4px;
}

.hero-image {
  position: relative;
}

.hero-image img {
  width: 100%;
  max-width: 500px;
  height: auto;
  animation: float 6s ease-in-out infinite;
}

.floating-card {
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 15px 25px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 10px;
  animation: float 5s ease-in-out infinite;
  color: #ffffff;
}

.card-1 {
  top: 20%;
  left: -10%;
  animation-delay: 1s;
}

.card-2 {
  bottom: 20%;
  right: -10%;
  animation-delay: 2s;
}

.floating-card i {
  color: #850212;
  font-size: 1.2em;
}

.bg-shape {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}

.shape-1 {
  width: 400px;
  height: 400px;
  top: -100px;
  right: -100px;
}

.shape-2 {
  width: 300px;
  height: 300px;
  bottom: -50px;
  left: -50px;
}

.shape-3 {
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@media (max-width: 992px) {
  .hero-container {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .hero-content h1 {
    font-size: 2.8em;
  }

  .hero-buttons {
    justify-content: center;
  }

  .hero-stats {
    justify-content: center;
  }

  .hero-image {
    order: -1;
  }

  .hero-image img {
    max-width: 400px;
  }
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2.3em;
  }

  .hero-image img {
    max-width: 300px;
  }

  .floating-card {
    padding: 10px 20px;
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .hero-buttons {
    flex-direction: column;
  }

  .hero-stats {
    flex-direction: column;
    gap: 20px;
  }
} 