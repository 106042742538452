
.header {
  width: 100%;
  background-color: #850212;
  padding: 20px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: relative;
  z-index: 1000;
}

.header-top {
  padding: 30px 0;
  border-bottom: 1px solid #000000;
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  width: 300px;
  height: auto;
  max-width: 100%;
}

.header-nav {
  background-color: #850212;
  border-bottom: 1px solid #000000;
}


@media (max-width: 992px) {
  .header-logo {
    width: 250px;
  }
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0;
  }
  
  .header-logo {
    width: 200px;
  }
}

@media (max-width: 480px) {
  .header-logo {
    width: 180px;
  }
}


.mobile-menu {
  position: fixed;
  top: 0;
  right: -100%;
  width: 80%;
  max-width: 300px;
  height: 100vh;
  background: #850212;
  padding: 60px 20px 20px;
  transition: right 0.3s ease;
  box-shadow: -2px 0 8px rgba(0,0,0,0.1);
  z-index: 1000;
}

.mobile-menu.active {
  right: 0;
}

.mobile-menu-links {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.mobile-menu a {
  color: #333;
  text-decoration: none;
  font-size: 1.1em;
  padding: 12px 16px;
  border-radius: 8px;
  transition: all 0.2s ease;
  position: relative;
  font-weight: 500;
}

.mobile-menu a:hover {
  background-color: #f8f9fa;
  color: #850212;
}

.mobile-menu a:active {
  background-color: #e9ecef;
  transform: scale(0.98);
}

.mobile-menu a.active {
  color: #850212;
  background-color: #e8f3ff;
}


.close-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 1.5em;
  color: #666;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.close-menu:hover {
  background-color: #f8f9fa;
  color: #333;
}

.close-menu:active {
  transform: scale(0.95);
}


.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 999;
}

.mobile-menu-overlay.active {
  opacity: 1;
  visibility: visible;
}


.header-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

@media (max-width: 768px) {
  .header-right {
    gap: 12px;
  }
} 