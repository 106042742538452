
.courses-filter {
  display: flex;
  gap: 20px;
  margin-bottom: 24px;
  align-items: center;
  justify-content: space-between;
}


.search-container {
  position: relative;
  max-width: 300px;
  width: 100%;
}


.search-input {
  width: 100%;
  padding: 10px 16px;
  padding-left: 40px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-size: 0.95em;
  transition: border-color 0.2s;
}


.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
}


.sort-container {
  width: 200px;
  flex-shrink: 0;
}

.sort-select {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-size: 0.95em;
  background-color: white;
  cursor: pointer;
  transition: border-color 0.2s;
}

@media (max-width: 992px) {
  .search-container {
    max-width: 150px;
  }
}

@media (max-width: 768px) {
  .courses-filter {
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .search-container,
  .sort-container {
    width: 100%;
    max-width: 100%;
  }

  .sort-container {
    width: 100%;
  }

  .search-input,
  .sort-select {
    font-size: 0.9em;
    padding: 8px 14px;
    width: 100%;
    box-sizing: border-box;
  }

  .search-input {
    padding-left: 36px;
  }

  .search-icon {
    left: 10px;
  }
}

@media (max-width: 480px) {
  .courses-filter {
    gap: 12px;
    margin-bottom: 20px;
    padding: 0 2px;
  }

  .search-input,
  .sort-select {
    font-size: 0.85em;
    padding: 8px 12px;
  }

  .search-input {
    padding-left: 32px;
  }
} 