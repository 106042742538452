.course-access {
  padding: 100px 20px;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  position: relative;
  overflow: hidden;
}

.animated-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.particle {
  position: absolute;
  border-radius: 50%;
  animation: float 15s infinite;
  opacity: 0.1;
}

.particle-1 {
  width: 300px;
  height: 300px;
  background: linear-gradient(45deg, #850212, #bb5a66);
  top: -150px;
  right: 10%;
  animation-delay: 0s;
}

.particle-2 {
  width: 200px;
  height: 200px;
  background: linear-gradient(-45deg, #850212, #bb5a66);
  bottom: -100px;
  left: 15%;
  animation-delay: -5s;
}

.particle-3 {
  width: 150px;
  height: 150px;
  background: linear-gradient(90deg, #850212, #bb5a66);
  top: 40%;
  left: -75px;
  animation-delay: -2s;
}

.particle-4 {
  width: 100px;
  height: 100px;
  background: linear-gradient(180deg, #850212, #bb5a66);
  top: 20%;
  right: -50px;
  animation-delay: -7s;
}

.course-access-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-wrapper {
  max-width: 600px;
}

.course-access h2 {
  font-size: 3em;
  margin-bottom: 25px;
  font-weight: 700;
  background: linear-gradient(45deg, #850212, #bb5a66);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}

.course-access p {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 40px;
  line-height: 1.6;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 40px;
}

.feature-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.feature-title {
  font-size: 1.2em;
  font-weight: 700;
  color: #333;
  margin-bottom: 5px;
  text-align: center;
}

.feature-description {
  font-size: 0.95em;
  color: #666;
  text-align: center;
  line-height: 1.4;
}

.feature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 25px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 60px;
  height: 60px;
  background: linear-gradient(45deg, #850212, #bb5a66);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.feature-icon i {
  color: white;
  font-size: 24px;
}

.access-button {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 15px 30px;
  background: linear-gradient(45deg, #850212, #bb5a66);
  color: white;
  text-decoration: none;
  border-radius: 30px;
  font-size: 1.1em;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(133, 2, 18, 0.3);
  white-space: nowrap;
}

.access-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(133, 2, 18, 0.4);
}

.access-button i {
  transition: transform 0.3s ease;
}

.access-button:hover i {
  transform: translateX(5px);
}

.decorative-element {
  position: relative;
  width: 400px;
  height: 400px;
}

.circle-pattern {
  position: relative;
  width: 100%;
  height: 100%;
}

.circle {
  position: absolute;
  border-radius: 50%;
  border: 2px solid rgba(133, 2, 18, 0.1);
  animation: rotate 20s linear infinite;
}

.c1 {
  width: 100%;
  height: 100%;
  border-color: rgba(133, 2, 18, 0.15);
  animation-duration: 30s;
}

.c2 {
  width: 70%;
  height: 70%;
  top: 15%;
  left: 15%;
  border-color: rgba(187, 90, 102, 0.15);
  animation-duration: 20s;
  animation-direction: reverse;
}

.c3 {
  width: 40%;
  height: 40%;
  top: 30%;
  left: 30%;
  border-color: rgba(133, 2, 18, 0.2);
  animation-duration: 15s;
}

@keyframes float {
  0%, 100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(10px, -10px);
  }
  50% {
    transform: translate(0, -20px);
  }
  75% {
    transform: translate(-10px, -10px);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 992px) {
  .course-access-container {
    flex-direction: column;
    text-align: center;
  }

  .decorative-element {
    width: 300px;
    height: 300px;
    margin-top: 40px;
  }

  .features-grid {
    max-width: 600px;
    margin: 0 auto 40px;
  }
}

@media (max-width: 768px) {
  .course-access {
    padding: 60px 20px;
  }

  .course-access h2 {
    font-size: 2.5em;
  }

  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .decorative-element {
    width: 250px;
    height: 250px;
  }

  .feature-title {
    font-size: 1.1em;
  }

  .feature-description {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .course-access h2 {
    font-size: 2em;
  }

  .course-access p {
    font-size: 1.1em;
  }

  .access-button {
    padding: 12px 24px;
    font-size: 1em;
    width: auto;
    max-width: 100%;
    margin: 0 auto;
  }

  .feature-icon {
    width: 50px;
    height: 50px;
  }

  .feature-icon i {
    font-size: 20px;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .feature-title {
    font-size: 1em;
  }

  .feature-description {
    font-size: 0.85em;
  }

  .feature-item {
    padding: 20px;
  }
} 