.user-reviews {
  padding: 100px 20px;
  position: relative;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  overflow: hidden;
}

.reviews-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.review-particle {
  position: absolute;
  border-radius: 50%;
  animation: float 15s infinite;
  opacity: 0.1;
}

.review-particle-1 {
  width: 250px;
  height: 250px;
  background: linear-gradient(45deg, #850212, #bb5a66);
  top: -100px;
  right: 15%;
  animation-delay: 0s;
}

.review-particle-2 {
  width: 180px;
  height: 180px;
  background: linear-gradient(-45deg, #850212, #bb5a66);
  bottom: -90px;
  left: 10%;
  animation-delay: -3s;
}

.review-particle-3 {
  width: 120px;
  height: 120px;
  background: linear-gradient(90deg, #850212, #bb5a66);
  top: 30%;
  left: -60px;
  animation-delay: -6s;
}

.user-reviews-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.reviews-header {
  text-align: center;
  margin-bottom: 60px;
  position: relative;
}

.reviews-header h2 {
  font-size: 2.8em;
  margin-bottom: 15px;
  font-weight: 700;
  background: linear-gradient(45deg, #850212, #bb5a66);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.reviews-subtitle {
  font-size: 1.2em;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
}

.reviews-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.review-card {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  border: 1px solid rgba(133, 2, 18, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.review-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
  border-color: #850212;
}

.quote-icon {
  position: absolute;
  top: -20px;
  right: 30px;
  width: 40px;
  height: 40px;
  background: linear-gradient(45deg, #850212, #bb5a66);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2em;
  box-shadow: 0 4px 15px rgba(133, 2, 18, 0.3);
}

.review-content {
  margin-bottom: 20px;
}

.review-text {
  font-size: 1.1em;
  color: #444;
  line-height: 1.6;
  margin: 20px 0;
}

.review-stars {
  color: #850212;
  font-size: 1.2em;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.review-author {
  display: flex;
  align-items: center;
  gap: 15px;
}

.author-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #850212;
}

.author-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.author-info h4 {
  color: #333;
  font-weight: 600;
  margin-bottom: 5px;
}

.author-info p {
  color: #666;
  font-size: 0.9em;
}

@keyframes float {
  0%, 100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(10px, -10px);
  }
  50% {
    transform: translate(0, -20px);
  }
  75% {
    transform: translate(-10px, -10px);
  }
}

@media (max-width: 992px) {
  .reviews-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .user-reviews {
    padding: 60px 20px;
  }

  .reviews-header h2 {
    font-size: 2.3em;
  }

  .reviews-grid {
    grid-template-columns: 1fr;
    gap: 40px;
  }
}

@media (max-width: 480px) {
  .reviews-header h2 {
    font-size: 2em;
  }

  .reviews-subtitle {
    font-size: 1.1em;
  }

  .review-card {
    padding: 25px 20px;
  }
} 