.language-switcher {
  position: relative;
  display: inline-block;
  margin-left: 20px;
  z-index: 1000;
}

.language-button {
  display: flex;
  align-items: center;
  background: rgb(255, 255, 255);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 8px 12px;
  color: #333;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  font-weight: 500;
  min-width: 85px;
}

.button-content {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
}

.language-button:hover {
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.3);
}

.language-button.active {
  background: rgba(255, 255, 255, 0.2);
}

.flag {
  font-size: 1.2em;
  line-height: 1;
  display: flex;
  align-items: center;
}

.language-name {
  font-size: 0.9em;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.arrow-icon {
  display: flex;
  align-items: center;
  transition: transform 0.2s ease;
  color: currentColor;
}

.arrow-icon.open {
  transform: rotate(180deg);
}

.language-dropdown {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  min-width: 180px;
  transform-origin: top right;
  animation: dropdownFadeIn 0.2s ease;
}

.option-content {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.language-option {
  display: flex;
  width: 100%;
  padding: 12px 16px;
  border: none;
  background: transparent;
  cursor: pointer;
  color: #333;
  transition: all 0.2s ease;
  font-size: 14px;
  position: relative;
}

.language-option:hover {
  background: #f5f7fa;
}

.language-option.active {
  color: #850212;
  background: #f0f7ff;
}

.check-icon {
  margin-left: auto;
  color: #850212;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {

  .language-switcher {
    padding-right: 35px;
  }

  .language-button {
    padding: 8px;
    min-width: unset;
    background: #ffffff;
    border: none;
  }
  
  .button-content {
    gap: 4px;
  }
  
  .language-name {
    display: block;

  }
  
  .arrow-icon {
    display: none;
  }
  
  .language-dropdown {
    right: -10px;
    min-width: 160px;
  }
  
  .option-content {
    gap: 8px;
  }
} 