@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skeleton-base {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 1000px 100%;
  animation: shimmer 2s infinite linear;
  border-radius: 8px;
}

.skeleton-title {
  composes: skeleton-base;
  width: 200px;
  height: 40px;
  margin: 0 auto 40px;
}

.skeleton-filter {
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

.skeleton-filter-title {
  composes: skeleton-base;
  width: 120px;
  height: 24px;
  margin-bottom: 24px;
}

.skeleton-filter-item {
  composes: skeleton-base;
  height: 20px;
  margin-bottom: 16px;
}

.skeleton-search-sort {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 20px;
}

.skeleton-search {
  composes: skeleton-base;
  width: 300px;
  height: 40px;
}

.skeleton-sort {
  composes: skeleton-base;
  width: 200px;
  height: 40px;
}

.skeleton-card {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

.skeleton-image {
  composes: skeleton-base;
  width: 100%;
  height: 180px;
}

.skeleton-content {
  padding: 16px;
}

.skeleton-title-card {
  composes: skeleton-base;
  width: 80%;
  height: 24px;
  margin-bottom: 16px;
}

.skeleton-text {
  composes: skeleton-base;
  width: 100%;
  height: 16px;
  margin-bottom: 12px;
}

.skeleton-text.short {
  width: 60%;
}

.skeleton-tags {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.skeleton-tag {
  composes: skeleton-base;
  width: 80px;
  height: 24px;
}

@media (max-width: 768px) {
  .skeleton-search-sort {
    flex-direction: column;
  }
  
  .skeleton-search,
  .skeleton-sort {
    width: 100%;
  }
} 