
.course-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(187, 200, 243, 0.3);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}


.course-modal-content {
  width: 100%;
  max-width: 1200px;
  margin: 20px;
  animation: slideIn 0.3s ease-out;
  background: white;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}


@keyframes fadeIn {
  from {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
  to {
    background-color: rgba(187, 200, 243, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.course-modal-content .course-details-section {
  padding: 0;
}

.course-modal-content .course-details-container {
  margin: 0;
  border-radius: 8px;
}

.course-modal-backdrop.closing {
  animation: fadeOut 0.3s ease-out forwards;
}

.course-modal-backdrop.closing .course-modal-content {
  animation: slideOut 0.3s ease-out forwards;
}

@keyframes fadeOut {
  from {
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-20px);
    opacity: 0;
  }
}

@supports not (backdrop-filter: blur(5px)) {
  .course-modal-backdrop {
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  @keyframes fadeIn {
    from {
      background-color: rgba(0, 0, 0, 0);
    }
    to {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
  
  @keyframes fadeOut {
    from {
      background-color: rgba(0, 0, 0, 0.7);
    }
    to {
      background-color: rgba(0, 0, 0, 0);
    }
  }
} 