
.course-section {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}


.course-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}


.filters-section {
  flex-shrink: 0;
  width: 300px;
}


.courses-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}


.course-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
}


.course-section h2 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 700;
  background: linear-gradient(45deg, #850212, #dc3545);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}

.course-section h2::after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background: linear-gradient(45deg, #850212, #dc3545);
  margin: 15px auto 0;
  border-radius: 2px;
}


.loading-message {
  padding: 20px;
  text-align: center;
  color: #666;
}

.error-message {
  padding: 20px;
  text-align: center;
  color: #dc3545;
}


.pagination {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  background-color: #f8f9fa;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 20px;
}

.pagination button.active {
  background-color: #850212;
  color: white;
  border-color: #850212;
}

.pagination button:hover:not(.active) {
  background-color: #e9ecef;
}

.pagination button.ellipsis {
  cursor: default;
  pointer-events: none;
  background-color: transparent;
  border: none;
}

@media (max-width: 992px) {
  .course-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 16px;
  }
}

@media (max-width: 768px) {
  .course-section {
    padding: 15px;
  }

  .course-container {
    flex-direction: column;
    gap: 16px;
  }

  .filters-section {
    width: 100%;
    margin-bottom: 0;
  }

  .courses-filter {
    flex-direction: column;
    gap: 12px;
  }

  .search-container,
  .sort-container {
    width: 100%;
  }

  .course-grid {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 15px;
  }

  .course-section h2 {
    font-size: 2em;
  }
}

@media (max-width: 480px) {
  .course-section {
    padding: 12px;
  }

  .course-grid {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .course-section h2 {
    font-size: 1.8em;
  }

  .pagination {
    margin-top: 20px;
  }

  .pagination button {
    padding: 6px 10px;
    font-size: 0.9em;
  }
} 