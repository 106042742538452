.category-cards-section {
  padding: 130px 20px;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}

.category-cards-container {
  max-width: 1200px;
  margin: 0 auto;
}

.category-cards-container h2 {
  text-align: center;
  font-size: 2.5em;
  color: #333;
  margin-bottom: 40px;
  font-weight: 700;
  background: linear-gradient(45deg, #850212, #bb5a66);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.category-cards-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  padding: 10px 0;
}

.category-card {
  background: #ffffff;
  border-radius: 12px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0,0,0,0.08);
  border: 1px solid rgba(0,0,0,0.05);
  position: relative;
  overflow: hidden;
}

.category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0,0,0,0.12);
}

.category-image-container {
  position: relative;
  width: 100%;
  height: 160px;
  overflow: hidden;
  border-radius: 12px;
}

.category-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.category-card:hover .category-image {
  transform: scale(1.15);
}

.category-title-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0,0,0,0.2),
    rgba(0,0,0,0.7)
  );
  display: flex;
  align-items: flex-end;
  padding: 15px;
  transition: background 0.3s ease;
}

.category-card:hover .category-title-overlay {
  background: linear-gradient(
    to bottom,
    rgba(255, 0, 43, 0.4),
    rgba(255, 0, 0, 0.8)
  );
}

.category-title-overlay h3 {
  color: #ffffff;
  font-size: 1.2em;
  font-weight: 600;
  margin: 0;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.3);
  transition: transform 0.3s ease;
  width: 100%;
  text-align: center;
}

.category-card:hover .category-title-overlay h3 {
  transform: translateY(-5px);
}

@media (max-width: 1200px) {
  .category-cards-grid {
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
  }
  
  .category-image-container {
    height: 140px;
  }
}

@media (max-width: 992px) {
  .category-cards-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .category-image-container {
    height: 160px;
  }
}

@media (max-width: 768px) {
  .category-cards-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .category-cards-container h2 {
    font-size: 2em;
  }
}

@media (max-width: 480px) {
  .category-cards-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .category-cards-container h2 {
    font-size: 1.8em;
  }
  
  .category-image-container {
    height: 120px;
  }
  
  .category-title-overlay h3 {
    font-size: 1em;
  }
  
  .category-title-overlay {
    padding: 10px;
  }
} 