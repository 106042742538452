.certificate-verification-page {
  min-height: calc(100vh - 200px);
  background: linear-gradient(135deg, #f6f9fc 0%, #eef2f7 100%);
  padding: 60px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.verification-container {
  max-width: 800px;
  width: 100%;
  background: white;
  border-radius: 24px;
  padding: 48px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  z-index: 1;
}

.verification-container.visible {
  opacity: 1;
  transform: translateY(0);
}

.verification-badge {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0 auto 32px;
}

.verification-icon {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #28a745, #20c997);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  animation: scaleIn 0.5s ease-out;
}

.verification-icon i {
  font-size: 3em;
  color: white;
  animation: pulse 2s infinite;
}

.badge-ring {
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 4px solid #28a745;
  border-radius: 50%;
  opacity: 0.2;
  animation: ripple 2s infinite;
}

.badge-ring-outer {
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  border: 4px solid #28a745;
  border-radius: 50%;
  opacity: 0.1;
  animation: ripple 2s infinite 1s;
}

.verification-container h1 {
  font-size: 2.8em;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.highlight {
  background: linear-gradient(120deg, #28a745 0%, #20c997 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
}

.checkmark {
  color: #28a745;
  font-size: 0.8em;
}

.main-message {
  font-size: 1.4em;
  color: #2c3e50;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.message-icon {
  color: #ffd700;
  font-size: 1.2em;
  animation: rotate 20s linear infinite;
}

.verification-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 40px;
}

.info-card {
  background: #f8f9fa;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  gap: 16px;
  align-items: flex-start;
  transition: transform 0.3s ease;
}

.info-card:hover {
  transform: translateY(-5px);
}

.info-card i {
  font-size: 2em;
  color: #28a745;
  margin-top: 4px;
}

.info-card h3 {
  color: #2c3e50;
  font-size: 1.2em;
  margin-bottom: 12px;
}

.info-card p {
  color: #495057;
  font-size: 1em;
  line-height: 1.6;
}

.verification-footer {
  border-top: 2px solid #e9ecef;
  padding-top: 32px;
  margin-top: 40px;
}

.verification-seal {
  display: flex;
  align-items: center;
  gap: 16px;
}

.verification-seal i {
  font-size: 2.5em;
  color: #28a745;
  opacity: 0.8;
}

.seal-info {
  text-align: left;
}

.timestamp {
  color: #6c757d;
  font-size: 0.95em;
  margin-bottom: 4px;
}

.platform-info {
  color: #2c3e50;
  font-weight: 600;
}

@keyframes scaleIn {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

@keyframes ripple {
  0% { transform: scale(0.8); opacity: 0.5; }
  100% { transform: scale(1.2); opacity: 0; }
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .certificate-verification-page {
    padding: 40px 16px;
  }

  .verification-container {
    padding: 32px;
  }

  .verification-info {
    grid-template-columns: 1fr;
  }

  .verification-container h1 {
    font-size: 2.2em;
  }

  .main-message {
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
  .verification-container {
    padding: 24px;
  }

  .verification-badge {
    width: 100px;
    height: 100px;
  }

  .verification-container h1 {
    font-size: 1.8em;
    flex-direction: column;
    gap: 8px;
  }

  .main-message {
    font-size: 1.1em;
    flex-direction: column;
  }

  .verification-seal {
    flex-direction: column;
    text-align: center;
  }

  .seal-info {
    text-align: center;
  }
}

.certificate-image-container {
  margin: 32px 0;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.certificate-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.certificate-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 32px 0;
  color: #6c757d;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #28a745;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.certificate-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin: 32px 0;
  color: #dc3545;
}

.certificate-error i {
  font-size: 2em;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .certificate-image-container {
    padding: 16px;
    margin: 24px 0;
  }
} 