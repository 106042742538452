.contact-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.contact-container {
  width: 40%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  flex: 1;
}

.contact-header {
  text-align: center;
  margin-bottom: 50px;
}

.contact-header h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
  font-weight: 700;
  background: linear-gradient(45deg, #850212, #bb5a66);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header-underline {
  width: 80px;
  height: 4px;
  background: linear-gradient(45deg, #850212, #bb5a66);
  margin: 0 auto;
  border-radius: 2px;
}

.contact-content {
  display: flex;
  gap: 60px;
  align-items: center !important;
}

.contact-info {
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.contact-info p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #666;
  margin-bottom: 20px;
}

.contact-methods {
  margin-top: 30px;
  font-weight: 500;
}

.contact-email {
  background: #f8f9fa;
  padding: 15px;
  border-radius: 5px;
  margin: 20px 0;
}

.whatsapp-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 14px;
  background: #25D366;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.1em;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
  margin: 20px 0;
  gap: 10px;
}

.whatsapp-button:hover {
  background: #128C7E;
  transform: translateY(-2px);
}

.whatsapp-button i {
  font-size: 1.2em;
}

@media (max-width: 992px) {
  .contact-container {
    width: 85%;
  }
}

@media (max-width: 768px) {
  .contact-container {
    width: 90%;
    padding: 40px 20px;
  }

  .contact-content {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .contact-header h1 {
    font-size: 2em;
  }

  .contact-info p,
  .form-group textarea {
    font-size: 1em;
  }

  .contact-info,
  .contact-form {
    padding: 25px;
  }

  .whatsapp-button {
    padding: 12px;
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .contact-container {
    width: 95%;
    padding: 30px 0;
  }

  .contact-header h1 {
    font-size: 1.8em;
  }

  .contact-info,
  .contact-form {
    padding: 20px;
  }
} 