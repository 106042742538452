.navbar {
  display: flex;
  align-items: center;
  width: 100%;
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  height: 80px;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: right;
  margin: 0;
  padding: 0;
  gap: 30px;
}

.nav-menu li a {
  color: #f0f0f0;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.1em;
  transition: color 0.3s ease;
}

.nav-menu li a:hover {
  color: #c18c8c;
}

.nav-buttons {
  display: flex;
  align-items: center;
  padding-left: 45px;

  gap: 20px;
}

.cta-button {
  padding: 10px 25px;
  background-color: #ffffff;
  color: #58010b;
  text-decoration: none;
  border-radius: 25px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #58010b;
  color: #f0f0f0;
}

.mobile-menu-button {
  display: none;
}


.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2.2rem;
  height: 2.2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.4rem;
  z-index: 1100;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.hamburger-menu:hover {
  background-color: rgba(255, 38, 0, 0.1);
}

.hamburger-line {
  width: 100%;
  height: 2px;
  background: #ffffff;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}


.mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  padding-right: 60px;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.98);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  padding-top: 80px;
}

.mobile-menu.active {
  opacity: 1;
  visibility: visible;
}

.mobile-menu-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
}

.mobile-nav-items {
  list-style: none;
  padding: 20px 0;
  margin: 0;
  text-align: center;
}

.mobile-nav-items li {
  margin: 25px 0;
  opacity: 0;
  transform: translateY(20px);
}

.mobile-menu.active .mobile-nav-items li {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease;
}

.mobile-nav-items li:nth-child(1) { transition-delay: 0.1s; }
.mobile-nav-items li:nth-child(2) { transition-delay: 0.2s; }
.mobile-nav-items li:nth-child(3) { transition-delay: 0.3s; }
.mobile-nav-items li:nth-child(4) { transition-delay: 0.4s; }

.mobile-nav-items a {
  font-size: 1.3em;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 12px 24px;
  border-radius: 8px;
  transition: all 0.3s ease;
  display: inline-block;
}

.mobile-nav-items a:hover {
  background-color: rgba(0, 123, 255, 0.1);
  transform: translateX(5px);
}


.hamburger-menu.active .hamburger-line:first-child {
  transform: rotate(45deg);
  background: #270005;
}

.hamburger-menu.active .hamburger-line:nth-child(2) {
  opacity: 0;
  transform: translateX(20px);
}

.hamburger-menu.active .hamburger-line:nth-child(3) {
  transform: rotate(-45deg);
  background: #270005;
}


@media (max-width: 768px) {
  .navbar {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: auto;
  }

  .navbar-container {
    height: 100%;
    padding: 0 20px;
  }

  .hamburger-menu {
    display: flex;
  }

  .nav-menu, .nav-buttons {
    display: none;
  }

  .mobile-menu {
    display: block;
    padding-top: 0px;
  }

  .mobile-menu.active {
    width: 100%;
  }

 
  .mobile-nav-items .cta-button {
    margin-top: 20px;
    padding: 12px 30px;
    font-size: 1.1em;
    width: auto;
    display: inline-block;
    background-color: #58010b;
    color: #ffffff;
  }
}

@media (max-width: 480px) {
  .navbar-container {
    padding: 0 15px;
  }

  .hamburger-menu {
    width: 2rem;
    height: 2rem;
  }

  .mobile-nav-items a {
    font-size: 1.2em;
    padding: 10px 20px;
  }
}

/* Add dropdown styles */
.nav-dropdown {
  position: relative;
}

.dropdown-toggle {
  background: none;
  border: none;
  color: #f0f0f0;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.1em;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.dropdown-toggle:hover {
  color: #c18c8c;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  padding: 10px 0;
  min-width: 200px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
}

.dropdown-menu.show {
  opacity: 1;
  visibility: visible;
}

.dropdown-menu a {
  color: #850212 !important;
  font-weight: 500;
  padding: 10px 20px;
  display: block;
  text-decoration: none;
  transition: background 0.2s ease;
}

.dropdown-menu a:hover {
  background-color: #850212 !important;
  color: white !important;
}

/* Mobile dropdown styles */
.mobile-menu .dropdown-item {
  position: relative;
  text-align: center;
}

.mobile-menu .dropdown-toggle {
  color: #333;
  font-weight: 600;
  font-size: 1.1em;
} 

.mobile-menu .dropdown-menu {
  position: static;
  background: transparent;
  box-shadow: none;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  transform: translateX(0%);
  text-align: center;
}

.mobile-menu .dropdown-menu.show {
  max-height: 500px;
}

.mobile-menu .dropdown-menu a {
  color: #333;
  padding: 10px 0;
  font-size: 0.9em;
  justify-content: center;
}

.mobile-menu .dropdown-menu a:hover {
  background: transparent;
  color: #c18c8c;
}

/* Remove bullet points in mobile menu */
.mobile-menu ul {
  list-style-type: none;
  padding-left: 0;
}

/* Center mobile menu items */
.mobile-nav-items ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-menu .dropdown-toggle {
  justify-content: center;
  width: 100%;
}

/* Adjust chevron positioning */
.mobile-menu .dropdown-toggle i {
  margin-left: 8px;
} 