.app-download-banner {
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  background: linear-gradient(45deg, #850212, #bb5a66);
  color: white;
  padding: 10px;
  z-index: 1000;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.15);
  transition: bottom 0.3s ease-in-out;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.app-download-banner.show {
  bottom: 0;
}

.banner-content {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 15px 30px;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease-out;
}

.banner-content.animate-in {
  opacity: 1;
  transform: translateY(0);
}

.banner-content.animate-out {
  opacity: 0;
  transform: translateY(20px);
}

.banner-text-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1;
}

.banner-icon {
  font-size: 2em;
  animation: float 3s ease-in-out infinite;
}

.banner-text {
  flex: 1;
}

.banner-text h3 {
  margin: 0;
  font-size: 1.3em;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.banner-text p {
  margin: 6px 0 0;
  font-size: 1em;
  opacity: 0.9;
  line-height: 1.4;
}

.banner-actions {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-right: 40px;
}

.store-badge {
  height: 44px;
  width: auto;
  transition: all 0.3s ease;
  filter: brightness(1.1);
}

.app-store-button:hover .store-badge,
.play-store-button:hover .store-badge {
  transform: translateY(-3px);
  filter: brightness(1.2);
}

.close-banner {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #666;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
}

.close-banner:hover {
  color: #333;
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@media (max-width: 768px) {
  .app-download-banner {
    padding: 16px;
  }

  .banner-content {
    flex-direction: column;
    padding: 15px 40px 15px 15px;
  }

  .banner-text-wrapper {
    flex-direction: column;
    gap: 12px;
  }

  .banner-actions {
    margin-right: 0;
  }

  .store-badge {
    height: 40px;
  }

  .close-banner {
    top: 10px;
    transform: none;
  }
}

@media (max-width: 480px) {
  .banner-text h3 {
    font-size: 1.1em;
  }

  .banner-text p {
    font-size: 0.9em;
  }

  .store-badge {
    height: 36px;
  }
} 