.privacy-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.privacy-container {
  width: 75%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  flex: 1;
}

.privacy-container h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 700;
  background: linear-gradient(45deg, #850212, #9f1323);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.privacy-section {
  background: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

.privacy-section h2 {
  font-size: 1.8em;
  color: #333;
  margin: 40px 0 20px;
}

.privacy-section h3 {
  font-size: 1.4em;
  color: #444;
  margin: 30px 0 15px;
}

.privacy-section p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #666;
  margin-bottom: 20px;
}

.privacy-section ul {
  padding-left: 20px;
  margin-bottom: 20px;
}

.privacy-section li {
  font-size: 1.1em;
  line-height: 1.6;
  color: #666;
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  .privacy-container {
    width: 85%;
  }
}

@media (max-width: 768px) {
  .privacy-container {
    width: 95%;
    padding: 40px 15px;
  }

  .privacy-container h1 {
    font-size: 2em;
  }

  .privacy-section {
    padding: 30px;
  }

  .privacy-section h2 {
    font-size: 1.6em;
  }

  .privacy-section h3 {
    font-size: 1.3em;
  }

  .privacy-section p,
  .privacy-section li {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
.privacy-container {
    width: 90%;
}

  .privacy-container h1 {
    font-size: 1.8em;
  }

  .privacy-section {
    padding: 20px;
  }
} 